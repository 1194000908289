import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {LayoutsModule} from "./layouts/layouts.module";
import {PagesModule} from "./pages/pages.module";

// Auth
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';

// Language
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {FilterService} from "./core/services/filter.service";
import {OrderManagementApiService} from "./core/services/order-management-api.service";
import {RoleBasedGuard} from "./core/guards/roleBased.guard";
import {ActionReducer, MetaReducer, StoreModule} from "@ngrx/store";
import {DatevManagementApiService} from "./core/services/datev-management-api.service";
import {UserManagementApiService} from "./core/services/user-management-api.service";
import {localStorageSync} from "ngrx-store-localstorage";
import {SetDefaultCompanyGuard} from "./core/guards/setDefaultCompany.guard";
import {DocumentManagementApiService} from "./core/services/document-management-api.service";
import {AppAuthGuard} from "./core/guards/appAuthGuard .guard";
import {UserService} from "./core/services/user.service";
import {NotificationService} from "./core/services/notification.service";
import {CompanyNewGuard} from "./core/guards/company-new.guard";
import {DatePipe} from "@angular/common";
import {ClearStatesService} from "./core/services/clearstates.service";
import {StatisticManagementApiService} from "./core/services/statistic-management-api.service";
import {authCodeFlowConfig} from "./core/services/authCodeFlow.config";
import {OrderTypeEffects} from "./core/models/order/ordertype/store/ordertype.effects";
import {ContacttypeEffects} from "./core/models/contacttype/store/contacttype.effects";
import {AddressTypeEffects} from "./core/models/addresstype/store/addresstype.effects";
import {CompanyEffects} from "./core/models/company/store/company.effects";
import {UserEffects} from "./core/models/user/store/user.effects";
import {TasktypeEffects} from "./core/models/order/tasktype/store/tasktype.effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {CustomerOverviewEffects} from "./core/models/customer/customerOverview/store/customerOverview.effects";
import {OrderOverviewEffects} from "./core/models/order/orderOverview/store/orderOverview.effects";
import {MessagesEffects} from "./core/models/message/store/messages.effects";
import {TenantEffects} from "./core/models/tenant/store/tenant.effects";
import {EffectsModule} from "@ngrx/effects";
import {CustomerEffects} from "./core/models/customer/store/customer.effects";
import {OrderStateOverviewEffects} from "./core/models/order/orderStateOverview/store/orderStateOverview.effects";
import {DatevEffects} from "./core/models/datev/store/datev.effects";
import {MessageConfigurationEffects} from "./core/models/messageConfiguration/store/messageConfiguration.effects";
import {OrderEffects} from "./core/models/order/store/order.effects";

import * as fromUser from './core/models/user/store/user.reducer';
import * as fromTenant from './core/models/tenant/store/tenant.reducer';
import * as fromCompany from './core/models/company/store/company.reducer';
import * as fromCustomer from './core/models/customer/store/customer.reducer';
import * as fromCustomerOverview from './core/models/customer/customerOverview/store/customerOverview.reducer';
import * as fromContactType from './core/models/contacttype/store/contacttype.reducer';
import * as fromOrderType from './core/models/order/ordertype/store/ordertype.reducer';
import * as fromTaskType from './core/models/order/tasktype/store/taskype.reducer';
import * as fromOrder from './core/models/order/store/order.reducer';
import * as fromOrderOverview from './core/models/order/orderOverview/store/orderOverview.reducer';
import * as fromAddressType from './core/models/addresstype/store/addresstype.reducer';
import * as fromOrderStateOverview from './core/models/order/orderStateOverview/store/orderStateOverview.reducer';
import * as fromDatev from './core/models/datev/store/datev.reducer';
import * as fromMessageConfig from './core/models/messageConfiguration/store/messageConfiguration.reducer';
import * as fromMessages from './core/models/message/store/messages.reducer';
import * as fromBatch from "./core/models/batch/store/batch.reducer"
import * as fromStatusMessage from "./core/models/statusMessage/store/status-messages.reducer"
import * as fromFAQ from "./core/models/faq/store/faq.reducer"
import * as fromEmployeeVacation from "./core/models/employee-vacation/store/employee-vacation.reducer"

import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgbDateAdapter} from "@ng-bootstrap/ng-bootstrap";
import {NgbStringAdapter} from "./core/services/NgbStringAdapter";
import {authAppInitializerFactory} from "./core/services/auth-app-initializer.factory";
import {authModuleConfig} from "./core/services/auth-module-config";
import {LoginGuard} from "./core/guards/login.guard";
import {SharedModule} from "./shared/shared.module";
import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {BatchEffects} from "./core/models/batch/store/batch.effects";
import {BatchManagementApiService} from "./core/services/batch-management-api.service";
import {ErrorPageComponent} from "./pages/error-page/error-page.component";
import {StatusMessagesEffects} from "./core/models/statusMessage/store/status-messages.effects";
import {UiManagementApiService} from "./core/services/ui-management-api.service";
import {FaqEffects} from "./core/models/faq/store/faq.effects";
import {FlatpickrModule} from "angularx-flatpickr";
import {SimplebarAngularModule} from "simplebar-angular";
import {LightboxModule} from "ngx-lightbox";
import {EmployeeVacationEffects} from "./core/models/employee-vacation/store/employee-vacation.effects";
import {EmployeeVacationApiService} from "./core/services/employee-vacation-api.service";
import * as Sentry from "@sentry/angular-ivy";
import {Router} from "@angular/router";
export function flatpickrFactory() {
    flatpickr.localize(German);
    return flatpickr;
}

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

// @ts-ignore
const metaReducers: Array<MetaReducer<State, any>> = [localStorageSyncReducer];

// registerLocaleData(localeDe, 'de', localeDeExtra);

// @ts-ignore
function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
    return localStorageSync({
        keys: [
            {company: ['companies', 'selectedCompany', 'activeCompany']},
            {user: ['users', 'selectedUser', 'loggedInUser']},
            {tenant: ['tenant']},
            {customer: ['selectedCustomer']},
            {customerOverview: ['page']},
            {contactType: ['contactTypes', 'contactTypesForSelectBox', 'selectedContactType']},
            {orderType: ['orderTypes', 'orderTypesForSelectBox', 'selectedOrderType']},
            {taskTypes: ['taskTypes', 'taskTypesForSelectBox', 'selectedTaskType']},
            {order: ['selectedOrder', 'filesOfSelectedOrder', 'ordersByPeriod', 'ordersForPlanning', 'ordersForCalendar']},
            {orderOverview: ['page']},
            {addressType: ['addressTypes', 'addressTypesForSelectBox', 'selectedAddressType']},
            {orderStateOverview: ['page']},
            {datev: ['datevMappings', 'datevImportOverview', 'isLoading']},
            {messages: ['messages']},
            {batch: ['batchStatus']},
            {statusMessages: ['statusMessages']},
            {faqs: ['faqs']},
            {employeeVacation: ['employeeVacation']}
        ],
        rehydrate: true,
        storage: sessionStorage
    })(reducer);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: false
};

@NgModule({
    declarations: [
        AppComponent,
        ErrorPageComponent
    ],
    imports: [
        FlatpickrModule.forRoot(),
        LightboxModule,
        PerfectScrollbarModule,
        StoreModule.forRoot({
                user: fromUser.reducer,
                company: fromCompany.reducer,
                tenant: fromTenant.reducer,
                customer: fromCustomer.reducer,
                customerOverview: fromCustomerOverview.reducer,
                contactType: fromContactType.reducer,
                orderType: fromOrderType.reducer,
                taskType: fromTaskType.reducer,
                order: fromOrder.reducer,
                orderOverview: fromOrderOverview.reducer,
                addressType: fromAddressType.reducer,
                orderStateOverview: fromOrderStateOverview.reducer,
                datev: fromDatev.reducer,
                messageConfig: fromMessageConfig.reducer,
                messages: fromMessages.reducer,
                batch: fromBatch.reducer,
                statusMessages: fromStatusMessage.reducer,
                faqs: fromFAQ.reducer,
                employeeVacation: fromEmployeeVacation.reducer
            },
            {metaReducers}),
        EffectsModule.forRoot([
            UserEffects,
            CompanyEffects,
            TenantEffects,
            CustomerEffects,
            CustomerOverviewEffects,
            ContacttypeEffects,
            OrderTypeEffects,
            TasktypeEffects,
            OrderOverviewEffects,
            OrderEffects,
            AddressTypeEffects,
            OrderStateOverviewEffects,
            DatevEffects,
            MessageConfigurationEffects,
            MessagesEffects,
            BatchEffects,
            StatusMessagesEffects,
            FaqEffects,
            EmployeeVacationEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        OAuthModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        PerfectScrollbarModule,
        SharedModule,
        LayoutsModule,
        PagesModule,
        SimplebarAngularModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        DatePipe,
        UserManagementApiService,
        OrderManagementApiService,
        DatevManagementApiService,
        BatchManagementApiService,
        UiManagementApiService,
        StatisticManagementApiService,
        DocumentManagementApiService,
        EmployeeVacationApiService,
        NotificationService,
        FilterService,
        AppAuthGuard,
        LoginGuard,
        RoleBasedGuard,
        CompanyNewGuard,
        SetDefaultCompanyGuard,
        ClearStatesService,
        [
            {provide: 'USER_MANAGEMENT', useValue: environment.USER_MANAGEMENT},
            {provide: 'ORDER_MANAGEMENT', useValue: environment.ORDER_MANAGEMENT},
            {provide: 'KEYCLOAK_URL', useValue: environment.KEYCLOAK_URL},
            {provide: 'DOCUMENT_MANAGEMENT', useValue: environment.DOCUMENT_MANAGEMENT},
            {provide: 'NOTIFICATION_MANAGEMENT', useValue: environment.NOTIFICATION_MANAGEMENT},
            {provide: 'STOMP_URL', useValue: environment.STOMP_URL},
            {provide: 'UI_MANAGEMENT', useValue: environment.UI_MANAGEMENT},
            {provide: 'EMPLOYEE_MANAGEMENT', useValue: environment.EMPLOYEE_MANAGEMENT},
            {provide: 'VERSION', useValue: environment.VERSION},
        ],
        //{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        UserService,
        {provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [UserService], multi: true},
        {provide: AuthConfig, useValue: authCodeFlowConfig},
        {provide: OAuthModuleConfig, useValue: authModuleConfig},
        {provide: OAuthStorage, useFactory: storageFactory},
        {provide: NgbDateAdapter, useClass: NgbStringAdapter},
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    exports: [
    ]
})
export class AppModule {
}
