import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from "./Toast/toast-service";
import {EmployeeVacationEntitlementModel} from "../models/employee-vacation/employee-vacation-entitlement.model";
import {EmployeeVacationModel} from "../models/employee-vacation/employee-vacation.model";
import {EmployeeVacationPeriodModel} from "../models/employee-vacation/employee-vacation-period.model";
import {HolidayResultModel} from "../models/employee-vacation/holiday-result.model";
import {Page} from "../models/page/page.model";
import {DatevImportDtoModel} from "../models/datev/datevImportDto.model";

@Injectable()
export class EmployeeVacationApiService {
    private REST_API_SERVER = this.employeeManagement;

    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastService,
        private translate: TranslateService,
        @Inject('EMPLOYEE_MANAGEMENT') private employeeManagement: string) {
    }


    public getEntitlements(employeeId: string): Observable<EmployeeVacationEntitlementModel[]> {
        return this.httpClient.get<EmployeeVacationEntitlementModel[]>(this.REST_API_SERVER + '/employee-management/employee/' + employeeId + '/vacation/entitlement').pipe(
            map((entitlements: any) => {
                    return EmployeeVacationEntitlementModel.fromServiceArray(entitlements);
                }
            ));
    }

    public addEntitlement(entitlement: EmployeeVacationEntitlementModel): Observable<EmployeeVacationEntitlementModel> {
        const url = this.REST_API_SERVER + '/employee-management/employee/' + entitlement.employeeId + '/vacation/entitlement';
        return this.httpClient.post<EmployeeVacationEntitlementModel>(url, EmployeeVacationEntitlementModel.toService(entitlement)).pipe(
            map((result: any) => {
                    this.toastrService.success(this.translate.instant('employeeVacationService.addEntitlement'));
                    const resultEntitlement = {...entitlement};
                    resultEntitlement.vacationEntitlementId = result.rawData;
                    return entitlement;
                }
            ));
    }

    public changeEntitlement(entitlement: EmployeeVacationEntitlementModel): Observable<EmployeeVacationEntitlementModel> {
        const url = this.REST_API_SERVER + '/employee-management/employee/' + entitlement.employeeId + '/vacation/entitlement/' + entitlement.vacationEntitlementId;
        return this.httpClient.put<EmployeeVacationEntitlementModel>(url, EmployeeVacationEntitlementModel.toService(entitlement)).pipe(
            map((result: any) => {
                    this.toastrService.success(this.translate.instant('employeeVacationService.changeEntitlement'));
                    return entitlement;
                }
            ));
    }

    public getVacation(employeeId: string[], year: number): Observable<EmployeeVacationModel[]> {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setFullYear(year, 1, 1);
        endDate.setFullYear(year, 12, 31);

        const startDateString = startDate.toISOString().split('T')[0]
        const endDateString = endDate.toISOString().split('T')[0];

        const url = this.REST_API_SERVER + '/employee-management/employee/' + employeeId.toString() + '/vacation?' +
            'startDate=' + startDateString +
            '&endDate=' + endDateString;

        return this.httpClient.get<EmployeeVacationModel[]>(url).pipe(
            map((vacations: any) => {
                    return EmployeeVacationModel.fromServiceArray(vacations);
                }
            ));
    }

    public addVacation(vacation: any): Observable<EmployeeVacationModel> {
        const url = this.REST_API_SERVER + '/employee-management/employee/' + vacation.employeeId + '/vacation';
        const resultVacation = {...vacation}
        resultVacation.startDate = vacation.startDate.toISOString().split('T')[0]
        resultVacation.endDate = vacation.endDate.toISOString().split('T')[0];

        return this.httpClient.post<EmployeeVacationModel>(url, EmployeeVacationModel.toServiceNewVacation(resultVacation)).pipe(
            map((vacations: any) => {
                return new EmployeeVacationModel(vacation.employeeId, vacations.rawData, new EmployeeVacationPeriodModel(vacation.startDate, vacation.endDate));
                }
            ));
    }

    public getHolidays(year: number, startMonth: number, endMonth: number, page: number, size: number): Observable<Page<HolidayResultModel>> {
        const url = this.REST_API_SERVER + '/employee-management/calendar/' + year + '/' + startMonth + '/to/' + endMonth + '?page=' +
            page +
            '&size=' +
            size +'&filterHolidays=true';

        return this.httpClient.get<HolidayResultModel>(url).pipe(
            map((holidays: any) => {

                const resultPage = new Page<HolidayResultModel>(
                    holidays.totalPages,
                    holidays.totalElements,
                    holidays.numberOfElements,
                    holidays.pageSize,
                    holidays.pageNumber,
                    HolidayResultModel.fromServiceArray(holidays.content));
                return resultPage;
                }
            ));
    }
}
