import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    createEventId,
    eventContent,
    resourceLabelClassNames,
    resourceLabelContent,
    resourceLaneClassNames,
    titleFormat
} from './event-utils';
import deLocale from '@fullcalendar/core/locales/de';
import {Store} from '@ngrx/store';
import {allUsersSelect, UserState} from '../../core/models/user/store';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../core/models/user/user.model';
import {DatePipe} from '@angular/common';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Task} from '../../core/models/order/task.model';
import {NgbModal, NgbOffcanvas} from '@ng-bootstrap/ng-bootstrap';
import {allOrdersSelect, OrderState} from '../../core/models/order/store';
import {Order} from '../../core/models/order/order.model';
import {saveOrderAction, selectAllOrdersAction} from '../../core/models/order/store/order.actions';
import {OrderStateOverviewState} from '../../core/models/order/orderStateOverview/store';
import {TaskStateEnum} from '../../core/models/order/taskStateEnum';
import {TranslateService} from '@ngx-translate/core';
import {activeCompanySelect, allCompaniesSelect, CompanyState} from '../../core/models/company/store';
import {Company} from '../../core/models/company/company.model';
import {Customer} from 'src/app/core/models/customer/customer.model';
import {CalendarOptions, DateSelectArg, EventApi, EventClickArg} from "@fullcalendar/core";
import {FullCalendarComponent} from "@fullcalendar/angular";
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import {employeeVacationSelect, EmployeeVacationState, holidaysSelect} from "../../core/models/employee-vacation/store";
import {HolidayResultModel} from "../../core/models/employee-vacation/holiday-result.model";
import {Page} from "../../core/models/page/page.model";
import {
    loadHolidaysAction,
    loadVacationAction
} from "../../core/models/employee-vacation/store/employee-vacation.actions";
import {bottom, createPopper} from '@popperjs/core';
import tippy from 'tippy.js';
import {EmployeeVacationModel} from "../../core/models/employee-vacation/employee-vacation.model";

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
    breadCrumbItems!: Array<{}>;
    constructor(
        private userStore: Store<UserState>,
        private orderStore: Store<OrderState>,
        private employeeVacationStore: Store<EmployeeVacationState>,
        private orderStateOverviewStore: Store<OrderStateOverviewState>,
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private translate: TranslateService,
        private companyStore: Store<CompanyState>,
        private renderer: Renderer2,
        private offcanvasService: NgbOffcanvas
    ) {
    }

    taskForm: FormGroup;

    users$: Observable<User[]> = this.userStore.select(allUsersSelect);
    usersSub: Subscription;
    users: User[] = [];
    usersForTag = [];

    orders: Order[] = [];

    orders$: Observable<Customer[]> = this.orderStore.select(allOrdersSelect);
    ordersSub: Subscription;

    activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    activeCompanySub: Subscription;
    activeCompany: Company;

    companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
    companiesSub: Subscription;
    companies: Company[];

    holidays$: Observable<Page<HolidayResultModel>> = this.employeeVacationStore.select(holidaysSelect);
    holidaysSub: Subscription;
    holidays: Page<HolidayResultModel>;

    vacations$: Observable<EmployeeVacationModel[]> = this.employeeVacationStore.select(employeeVacationSelect)
    vacationSub: Subscription;
    vacations: EmployeeVacationModel[];

    sideBarHeader: string = null;
    sideBarOrder: Order = null;
    sideBarTask: Task = null;
    sideBarCustomerId: string = null;
    sidebarStartDate: string = null;
    sidebarEndDate: string = null;
    sidebarEmployeeId: string = null;
    sidebarOrders: Order[] = null;

    @ViewChild('calendar') calendar: FullCalendarComponent;
    @ViewChild('rightBar', {static: true}) rightBar: TemplateRef<any>;

    calendarOptions: CalendarOptions = {
        customButtons: {
            myCustomButton: {
                text: 'Aufgaben',
                click: () => this.handleSidebarClick()
            }
        },
        locale: deLocale,
        businessHours: {
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '07:00',
            endTime: '17:00',
        },
        headerToolbar: {
            left: 'title',
            center: 'prev next today',
            right: 'resourceTimelineWeek,upspotMonth,listMonth myCustomButton',
        },
        titleFormat: titleFormat.bind(this, this.datePipe),
        views: {
            upspotMonth: {
                slotLabelInterval: {days: 1},
                type: 'resourceTimeline',
                buttonText: '30 Tage',
                duration: {days: 30},
                visibleRange: function (currentDate) {
                    // Generate a new date for manipulating in the next step
                    const startDate = new Date(currentDate.valueOf());
                    const endDate = new Date(currentDate.valueOf());

                    // Adjust the start & end dates, respectively
                    startDate.setDate(startDate.getDate() - 1); // One day in the past
                    endDate.setDate(endDate.getDate() + 2); // Two days into the future

                    return {start: startDate, end: endDate};
                },
            },
            listMonth: {
                displayEventTime: false
            }
        },
        resourceGroupField: 'groupId',
        initialView: 'resourceTimelineWeek',
        weekends: false,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        eventChange: this.changeEvent.bind(this),
        resourceAreaHeaderContent: 'Mitarbeiter',
        resourceLabelClassNames: resourceLabelClassNames.bind(this),
        resourceLaneClassNames: resourceLaneClassNames.bind(this),
        resourceLabelContent: resourceLabelContent.bind(this),
        //eventDragStop: this.eventDrop.bind(this),
        //eventDragStart: this.eventDrag.bind(this),
        slotDuration: {day: 1},
        slotLabelFormat: [
            {day: 'numeric', weekday: 'short'}
        ],
        slotLabelContent: (arg) => {
            let html = arg.text.replace(',', ' ');
            const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            if (arg.date.getTime() === today.getTime()) {
                html = `<li>` + arg.text + `</li> `;
            }
            return {
                html,
            };
        },
        expandRows: true,
        height: 'auto',
        resourceAreaWidth: '150px',
        eventContent: eventContent.bind(this, this.translate, this.datePipe),
        eventReceive: this.addEvent.bind(this),
        datesSet: this.datesChanged.bind(this),
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        eventMouseEnter: this.handleMouseEnter.bind(this),
        eventMouseLeave: this.handleMouseLeave.bind(this),
        plugins: [
            interactionPlugin,
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            resourceTimelinePlugin]
    };
    currentEvents: EventApi[] = [];

    private handleMouseEnter(info): void {
        const tipp = tippy(info.el, {placement: bottom, arrow: true, content: info.event.title})
    }

    private handleMouseLeave(info): void {

    }

    private loadOrdersForCalendar(): void {
        const startDate = this.calendar?.getApi()?.getCurrentData().viewApi?.activeStart;
        const endDate = this.calendar?.getApi()?.getCurrentData().viewApi?.activeEnd;
        //this.calendar?.getApi()?.removeAllEvents();
        this.removeEvents();
        this.calendar?.getApi()?. getResources()?.forEach(resource => {
            if (resource?.extendedProps?.externCompany) {
                resource.remove();
            }
        });
        this.orderStore.dispatch(selectAllOrdersAction({startDate, endDate, filterActiveCompany: true}));
        // immer Passend dazu die Feiertage laden!
        this.loadHolidays(startDate, endDate);
    }

    private loadHolidays(startDate: Date, endDate: Date) {
        this.employeeVacationStore.dispatch(loadHolidaysAction({year: startDate.getFullYear(), startMonth: startDate.getMonth()+1, endMonth: endDate.getMonth()+1, page: 0, size: 100}));
    }

    private datesChanged(event): void {
        this.loadOrdersForCalendar();
    }

    private addEvent(event): void {
        const task = this.setStartAndEnddateFromEvent(event.event.extendedProps.task, event);
        this.saveOrder(event, task);
    }

    private saveOrder(event, task: Task) {
        let order: Order = event.event.extendedProps.order;
        const findOrder = this.orders.find(orderToFind => orderToFind.id === order.id);
        if (findOrder) {
            const customerId = order.customerId;
            const userIds = event.event.getResources()[0].id;

            if (!task.userIds) {
                task.userIds = [];
            }
            if (task.userIds.indexOf(userIds) === -1) {
                task.userIds.push(userIds);
            }
            task.state = TaskStateEnum.SCHEDULED;
            const taskIndex = order.tasks.findIndex(taskFind => taskFind.id === task.id);
            const tasks = [...findOrder.tasks];
            if (taskIndex > -1) {
                tasks.splice(taskIndex, 1);
                tasks.push(task);
            }
            order = {...findOrder, tasks: tasks};
            order.customerId = customerId;
            this.orderStore.dispatch(saveOrderAction({payload: order, customerId}));
            this.offcanvasService.dismiss();
        }
    }

    private setStartAndEnddateFromEvent(tasks, event): Task {
        const newTask = {...tasks};
        newTask.startDate = event.event.start;
        newTask.endDate = event.event.end;
        newTask.userIds = [];
        newTask.userIds.push(event.event.getResources()[0].id);
        return newTask;
    }

    private addOrders(customers: Customer[]): void {
        //this.calendar?.getApi()?.removeAllEvents();
        this.removeEvents();
        customers.forEach((customer: Customer) => {
                customer.orders.forEach((order: Order) => {
                    this.orders.push(order);
                    order.tasks.forEach(task => {
                        this.addEventToCalendar(order, task, customer);
                    });
                });
            }
        );
    }

    private removeEvents() {
        this.calendar?.getApi()?.getEvents().forEach((event, i) => {
           if (event.extendedProps?.task) {
               event.remove();
           }
        });
    }

    private changeEvent(event): void {
        //TODO Auftrag speichern und nicht Task!!
        const task = this.setStartAndEnddateFromEvent(event.event.extendedProps.task, event);
        this.saveOrder(event, task);
        //this.orderStore.dispatch(changeTaskAction({task: task, orderId, customerId}));
    }

    ngAfterViewInit(): void {
        this.activeCompanySub = this.activeCompany$.subscribe(company => {
            if (company) {
                this.activeCompany = company;
                this.loadOrdersForCalendar();
                this.orderStore.dispatch(selectAllOrdersAction({
                    startDate: null,
                    endDate: null,
                    filterActiveCompany: false
                }));
            }
        });
        this.companiesSub = this.companies$.subscribe(companies => this.companies = companies);

        this.usersSub = this.users$.subscribe(
            (users: User[]) => this.usersLoaded(users)
        );

        this.ordersSub = this.orders$.subscribe(
            (customers: Customer[]) => {
                if (customers) {
                    this.orders = [];
                    this.addOrders(customers);
                }
            }
        );

        this.holidaysSub = this.holidays$.subscribe(holidays => {
            if (holidays) {
                this.holidays = holidays;
                this.holidays.content?.forEach(holiday => {
                    this.calendar?.getApi().addEvent({start:holiday.date, end: holiday.date, display: 'background', title: holiday.holiday.name})
                });
            }
        });

        this.vacationSub = this.vacations$.subscribe( vacations => {
            this.vacations = [];
            if (vacations && vacations.length > 0) {
                this.vacations = vacations;
                this.vacations.forEach(vacation => {
                    this.calendar.getApi().addEvent({
                        id: createEventId(),
                        resourceId: vacation.employeeId,
                        start: vacation.period.startDate,
                        end: vacation.period.endDate,
                        title: 'Urlaub',
                    });
                });
            }
        });
    }

    dateToEpoch(date: Date, startDay: boolean): Date {
        if (startDay) {
            const newDate = new Date(date.setHours(0, 0, 0, 0));
            return new Date( newDate.setDate(newDate.getDate() ))
        } else {
            const newDate = new Date(date.setHours(23, 59, 59, 999));
            return new Date( newDate.setDate(newDate.getDate() ))
        }
    }

    private addEventToCalendar(order: Order, task: Task, customer: Customer): void {
        task.userIds?.forEach(userId => {
            let hasUserActiveCompany = true;
            if (!this.calendar.getApi().getResourceById(userId)) {
                const user = this.users.find(userToFind => userToFind.userId === userId);
                if (user) {
                    const name = user.firstName + ' ' + user.lastName;
                    let companyString = '';
                    let externCompany = false;
                    hasUserActiveCompany = user.companies.findIndex(company => company.companyId === this.activeCompany.companyId) > -1;
                    user.companies.forEach(company => {
                        const foundCompany = this.companies.find(comp => comp.companyId === company.companyId);
                        if (foundCompany && foundCompany.companyId !== this.activeCompany.companyId) {
                            companyString = companyString + foundCompany.address.companyName;
                            externCompany = true;
                        }
                    });
                    //if (hasUserActiveCompany) {
                        this.calendar.getApi().addResource({
                            id: user.userId,
                            groupId: 'Externe Mitarbeiter',
                            title: name,
                            extendedProps: {backgroundColor: user.color, externCompany, companyString}
                        });
                    //}
                }
            }
            //if( hasUserActiveCompany) {
            let externOrder = this.activeCompany.companyId !== order.companyId;
            this.calendar.getApi().addEvent({
                id: createEventId(),
                resourceId: userId,
                start: task.startDate,
                end: task.endDate,
                title: task.taskType?.description + (order.externalId?.id ? ' | ' + order.externalId?.id : ''),
                description: order.description,
                backgroundColor: task.taskType?.rgbaColor,
                borderColor: task.taskType?.color,
                extendedProps: {
                    orderId: order.id,
                    customer,
                    order,
                    task,
                    externOrder
                }
            });
            //}
        });
    }

    usersLoaded(userArray: User[]): void {
        this.calendar.getApi().getResources().forEach(ressource => {
            ressource.remove();
        });
        this.calendar.getApi().addResource({
            id: '-1',
            title: 'Vorplanung',
            groupId: 'Vorplanung'
        });
        this.users = userArray;
        const userIds = [];
        this.users?.forEach(user => {
            userIds.push(user.userId);
        });
        for (const user of userArray) {
            const name = user.firstName + ' ' + user.lastName;
            const foundCompany = user.companies?.find(company => company.companyId === this.activeCompany?.companyId);
            if (foundCompany && user.group?.name === 'GROUP_MITARBEITER') {
                this.calendar.getApi().addResource({
                    id: user.userId,
                    groupId: 'Mitarbeiter',
                    title: name,
                    extendedProps: {backgroundColor: user.color}
                });
            }

            const userForTag = {
                display: name,
                value: user.userId
            };
            this.usersForTag.push(userForTag);
        }
        this.taskForm = new FormGroup({
            id: new FormControl(),
            index: new FormControl(),
            description: new FormControl(),
            startDate: new FormControl(),
            endDate: new FormControl(),
            userIds: new FormControl(),
            chatMessages: new FormArray([]),
            sortOrder: new FormControl(),
            customer: new FormControl(),
            orderId: new FormControl(),
            state: new FormControl(),
            allowedStateTransitions: new FormControl()
        });

        this.employeeVacationStore.dispatch(loadVacationAction({employeeIds: userIds, year: 2023}));
    }

    ngOnInit(): void {
        /**
         * BreadCrumb
         */
        this.breadCrumbItems = [
            {label: 'menu.navigation'},
            {label: 'menu.calendar', active: true}
        ];
    }

    objectToJson(object: any): string {
        return JSON.stringify(object);
    }


    handleSidebarClick(): void {
        this.sidebarOrders = this.orders;
        this.openSideBar();
    }

    openSideBar(): void {
        const view = this.offcanvasService.open(this.rightBar, { position: 'end' });
        view.dismissed.subscribe(closed => {
            this.sideBarOrder = null;
            this.sideBarTask = null;
            this.sideBarCustomerId = null;
            this.sideBarHeader = null;
            this.sidebarEndDate = null;
            this.sidebarStartDate = null;
            this.sidebarEmployeeId = null;
            this.sidebarOrders = null;
        });
    };

    handleDateSelect(selectInfo: DateSelectArg): void {
        this.sidebarEmployeeId = selectInfo.resource.id;
        this.sidebarStartDate = selectInfo.startStr;
        const endDate = new Date(selectInfo.endStr);
        endDate.setDate(endDate.getDate()-1);
        this.sidebarEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd')
        this.sidebarOrders = this.orders;
        this.sideBarCustomerId
        this.openSideBar();
        //this.sidebar.openOrderList(this.orders, selectInfo.startStr, selectInfo.endStr, calendarApi, selectInfo.resource.id);
    }

    handleEventClick(clickInfo: EventClickArg): void {
        this.sideBarTask = clickInfo?.event?.extendedProps?.task;
        this.sideBarOrder = clickInfo?.event.extendedProps.order;
        this.sideBarCustomerId = this.sideBarOrder.customerId;
        this.sideBarHeader = '';
        if (this.sideBarOrder.externalId?.id && this.sideBarTask.taskType?.description) {
            this.sideBarHeader = this.sideBarOrder.externalId?.id + ' ' + this.sideBarTask.taskType?.description
        } else if (this.sideBarTask.taskType?.description) {
            this.sideBarHeader = this.sideBarTask.taskType?.description
        } else if (this.sideBarOrder.externalId?.id) {
            this.sideBarHeader = this.sideBarOrder.externalId?.id
        } else {
            this.sideBarHeader = 'Aufgabe bearbeiten'
        }
        this.openSideBar();
        //this.sidebar.openTaskInSidebar(task, order, header);
    }

    handleEvents(events: EventApi[]): void {
        this.currentEvents = events;
    }

    ngOnDestroy(): void {
        this.usersSub.unsubscribe();
        this.ordersSub.unsubscribe();
        this.activeCompanySub.unsubscribe();
        this.companiesSub.unsubscribe();
        this.vacationSub.unsubscribe();
        this.holidaysSub.unsubscribe();
    }

    changeWeekendStatus(checked: boolean): void {
        this.calendarOptions.weekends = checked;
    }

    changeMinutesView(checked: boolean): void {
        if (checked) {
            this.calendarOptions.slotDuration = '00:30';
            this.calendarOptions.slotMinTime = '07:00:00';
            this.calendarOptions.slotMaxTime = '17:00:00';
            this.calendarOptions.slotLabelFormat = [{hour: 'numeric', day: 'numeric', weekday: 'short'}];
        } else {
            this.calendarOptions.slotDuration = {days: 1};
            this.calendarOptions.slotMinTime = '00:00:00';
            this.calendarOptions.slotMaxTime = '24:00:00';
            this.calendarOptions.slotLabelFormat = [{day: 'numeric', weekday: 'short'}];
        }
    }

    orderOpened(opened: boolean) {
        this.offcanvasService.dismiss();
    }
    planTask(task: Task): void {

        if (!this.sidebarEmployeeId) {
            this.sidebarEmployeeId = '-1';
        }

        if (!this.sidebarStartDate) {
            this.sidebarStartDate =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        if (!this.sidebarEndDate) {
            this.sidebarEndDate =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        const newTask = {...task};
        newTask.startDate = new Date(this.sidebarStartDate);
        newTask.endDate = new Date(this.sidebarEndDate)
        newTask.userIds = [];
        newTask.userIds.push(this.sidebarEmployeeId);

        let findOrder: Order = null;
        this.orders.forEach(order => {
            if (order.tasks.find(_task => _task.id === task.id)) {
                findOrder = order;
            }
        });
        if (findOrder) {
            const customerId = findOrder.customerId;
            newTask.state = TaskStateEnum.SCHEDULED;
            const taskIndex = findOrder.tasks.findIndex(taskFind => taskFind.id === newTask.id);
            const tasks = [...findOrder.tasks];
            if (taskIndex > -1) {
                tasks.splice(taskIndex, 1);
                tasks.push(newTask);
            }
            findOrder = {...findOrder, tasks: tasks};
            findOrder.customerId = customerId;
            this.orderStore.dispatch(saveOrderAction({payload: findOrder, customerId}));
            this.offcanvasService.dismiss();
        }
    }
}
